"use client";
import { get3DModelLink, getBrandLink, getCatalogLink, getDesignExampleLink, getEbookLink, getProductCenterLink } from "@/lib/util/get-router";
import ThemeLink from "@/components/link";
import React, { useEffect, useState } from "react";
import { usePathname } from "next/navigation";

const menu = [{
    id: 1,
    title: "商城首页",
    link: "/"
}, {
    id: 2,
    title: "产品中心",
    link: getProductCenterLink()
}, {
    id: 3,
    title: "品牌中心",
    link: getBrandLink()
}, {
    id: 4,
    title: "目录资料",
    link: getCatalogLink()
}, {
    id: 5,
    title: "设计案例",
    link: getDesignExampleLink()
},{
    id: 6,
    title: "资源开发",
    link: ''
}, {
    id: 7,
    title: "恒配达论坛",
    link: ''
}, {
    id: 8,
    title: "产品知识",
    link: ''
},{
    id: 9,
    title: "设计软件",
    link: ''
}, {
    id: 10,
    title: "电子书",
    link: getEbookLink()
},


];
export default function Menu() {
    const pathname = usePathname();
    const [activeRouter, setActiveRouter] = useState(pathname);
    useEffect(() => {
        setActiveRouter(pathname);
    }, [pathname]);
    return <>
        {
            menu.map(item => {
                return <li key={item.id}>
                    <ThemeLink href={item.link}
                        className={` ${item.link === activeRouter ? "!text-main" : "!text-black hover:!text-main"}`}>{item.title}</ThemeLink>
                </li>;
            })
        }
    </>;
}
