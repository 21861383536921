// 获取登录界面链接
import myCart from "@/components/header/nav-bar/my-cart";

export const getLoginLink = () => {
    return "/login";
};

// 获取注册界面链接
export const getRegisterLink = () => {
    return "/register";
};

// 获取忘记密码界面链接
export const getForgotPasswordLink = () => {
    return "/retrieve";
};

// 获取注册协议界面链接
export const getRegisterProtocolLink = () => {
    return "/protocol";
};
// 获取隐私条款界面链接
export const getPrivacyPolicyLink = () => {
    return "/privacy";
};

// 获取产品中心链接
export const getProductCenterLink = () => {
    return "/product-center";
};

// 获取产品中心具体分类链接
export const getProductCenterCategoryLink = (id:string) => {
    return `/product-center/${id}`;
};


export const getProductCenterSlugLink = (pid: string,id:string) => {
    return `${getProductCenterLink()}/${pid}/${id}`;
};

export const getProductLink = () => {
    return "/product";
};
// 获取产品分类详情界面链接
// export const getProductCateLink = ([slug2]:string)=>{
//     return "/product-category"
// }

// 获取产品详情界面链接
export const getProductDetailLink = (slug: string) => {
    return `${getProductLink()}/${slug}`;
};

// 获取品牌中心界面链接
export const getBrandLink = () => {
    return "/brand";
};

// 获取目录资料界面链接
export const getCatalogLink = () => {
    return "/catalog";
};

// 获取3D模型界面链接
export const get3DModelLink = () => {
    return "/3d-model";
};

// 获取电子书界面链接
export const getEbookLink = () => {
    return "/ebook";
};

// 获取个人中心界面链接
export const getCenterLink = () => {
    return "/center";
};

// 获取动态资讯界面
export const getDynamicLink = () => {
    return "/dynamic";
};

// 获取产品知识界面
export const getDynamicKnowledgeLink = () => {
    return `${getDynamicLink()}/product-knowledge`;
};

// 获取行业资讯界面
export const getDynamicIndustryNewsLink = () => {
    return `${getDynamicLink()}/industry-news`;
};

// 获取个人中心账户信息界面
export const getCenterAccountInfoLink = () => {
    return `${getCenterLink()}/account-info`;
};

// 获取个人中心账户安全界面链接
export const getCenterAccountLink = () => {
    return `${getCenterLink()}/account-security`;
};

// 获取个人中心收货地址界面链接
export const getCenterAddressLink = () => {
    return `${getCenterLink()}/address`;
};

// 获取个人中心我的消息界面链接
export const getCenterMessageLink = () => {
    return `${getCenterLink()}/message`;
};

// 获取个人中心发票管理界面链接
export const getCenterInvoiceLink = () => {
    return `${getCenterLink()}/invoice`;
};

// 获取个人中心我的足迹界面链接
export const getCenterFootprintLink = () => {
    return `${getCenterLink()}/footprint`;
};

// 获取个人中心订单管理界面链接
export const getCenterOrderLink = () => {
    return `${getCenterLink()}/order`;
};

// 获取个人中心我的订单界面
export const getCenterOrderDetailLink = () => {
    return `${getCenterLink()}/my-order`;
};

// 获取个人中心我的询价界面
export const getCenterInquiryLink = () => {
    return `${getCenterLink()}/inquiry`;
};

// 获取个人中心我的收藏界面
export const getCenterCollectLink = () => {
    return `${getCenterLink()}/collect`;
};

// 获取个人中心我的积分界面
export const getCenterPointsLink = () => {
    return `${getCenterLink()}/points`;
};

// 获取个人界面积分详情界面
export const getCenterPointsDetailLink = () => {
    return `${getCenterLink()}/points-details`;
};

// 获取个人中心兑换列表界面
export const getCenterOrderExchangeLink = () => {
    return `${getCenterLink()}/exchange`;
};

// 获取待付款界面
export const getCenterWaitPayLink = () => {
    return `${getCenterOrderLink()}/wait-pay`;
};

// 获取待发货
export const getCenterWaitSendLink = () => {
    return `${getCenterOrderLink()}/wait-send`;
};

// 获取待收货
export const getCenterWaitReceiveLink = () => {
    return `${getCenterOrderLink()}/wait-receive`;
};

// 获取待开票
export const getCenterWaitInvoiceLink = () => {
    return `${getCenterOrderLink()}/wait-invoice`;
};

// 获取退换货
export const getCenterWaitReturnLink = () => {
    return `${getCenterOrderLink()}/wait-return`;
};

export  const getCenterShopcartLink=()=>{
    return `${getCenterLink()}/shopcart`
}
// 获取指定产品计算选型界面链接
export const getCalculationSelectionLink = (slug: string) => {
    return `/calculation-selection/${slug}`;
};

// 获取所有可计算选型产品界面链接
export const getAllCalculationSelectionLink = () => {
    return `/calculation-selection/all`;
};

// 获取自动计算界面链接
export const getAutomaticCalculationLink = (slug: string) => {
    return `/automatic-calculation/${slug}`;
};

// 获取所有可自动计算产品界面链接
export const getAllAutomaticCalculationLink = () => {
    return `/automatic-calculation/all`;
};

// 获取 设计案例链接
export const getDesignExampleLink = () => {
    return "/example";
};

//立即下单页面
export const getCheckoutLink = () => {
    return "/checkout";
};

//支付页面  订单id存checkout.ts中的orderId
export const getPayLink = () => {
    return `/pay`
}

//帮助中心
export const getHelpCenterLink = () => {
    return '/help-center'
}