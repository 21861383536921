"use client";
import {Badge, Button} from "antd";
import {ShoppingCartOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {useCartStore} from "@/lib/store/cart.store";
import CartDrawer from "@/components/header/nav-bar/cart-drawer";

type Props = {
    count: number
}

function MyCart({
                    count
                }: Props) {
    const [total, setTotal] = useState(count);
    const [open, setOpen] = useState(false);
    const {cartCount, setCartCount, getCartList} = useCartStore();
    useEffect(() => {
        setTotal(cartCount);
        cartCount && getCartList();
    }, [cartCount]);
    useEffect(() => {
        setCartCount(count);
    }, [count]);
    return <>
        <Button size="large" className="!rounded-[0] group" onClick={() => setOpen(true)}>
            <div className="text-themeP group-hover:text-main duration-300 b-flex gap-x-3">
                <Badge count={total} size="small" color="#ff5f00">
                    <ShoppingCartOutlined className="text-lg !text-themeP"/>
                </Badge>
                <span>
                我的购物车
            </span>
            </div>
        </Button>
        <CartDrawer open={open} onClose={() => setOpen(false)}  />
    </>;
}

export default React.memo(MyCart);
